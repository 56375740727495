var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vx-card", { staticClass: "overflow-hidden shadow-none" }, [
    _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
      _c("div", { staticClass: "p-6 text-center" }, [
        _c("img", {
          staticClass: "p-3 inline-flex",
          attrs: { src: _vm.iconPath, width: "50px" }
        }),
        _c("div", { staticClass: "truncate" }, [
          _c("span", [_vm._v(_vm._s(_vm.title))])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }