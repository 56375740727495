import {userHasAuthorization} from '@/modules/Auth/Helpers/drmHelper.js';

function preparePermissionsArray(permissionsArray, uuid) {
  return permissionsArray.map(permission => ({...permission, uuid}))
}

function resourceHasAllFeatures(featuresNeeded, resourceFeatures) {
  if (!featuresNeeded) {
    return true
  }

  const featuresMissing = featuresNeeded.filter(featureNeeded => !resourceFeatures.includes(featureNeeded))
  return featuresMissing.length === 0
}

export const generateMenu = (items, uuid, features = []) => {
  return items
    .map(section => {
      return {
        title: section.title,
        subtitle: section.subtitle,
        items: section.items
          .filter(item => {
            const userHasPermission = item.hasOwnProperty('route')
              ? !item.route.meta.hasOwnProperty('drm') || userHasAuthorization(preparePermissionsArray(item.route.meta.drm, uuid))
              : !item.action.hasOwnProperty('drm') || userHasAuthorization(preparePermissionsArray(item.action.drm, uuid))

            return userHasPermission && resourceHasAllFeatures(item.features, features)
          })
      }
    })
    .filter(section => section.items.length !== 0)
}
