export const routesByName = (routes) => {
  return routes.reduce((acc, route) => {
    acc[route.name] = route;
    if (route.children) {
      const subRoutes = routesByName(routes.children)
      acc = {...acc, ...subRoutes}
    }
    return acc;
  }, {})
}

