<template>
    <div class="flex flex-wrap">
      <div v-for="pannel in pannels" :key="pannel.title" class="w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 pr-8">
        <h4>{{ $t(pannel.title) }}</h4>
        <vs-divider />
        <p class="">{{ $t(pannel.subtitle) }}</p>
        <div class="vx-row mt-4">
          <div v-for="item in pannel.items" :key="item.title" class="vx-col w-1/2 md:w-1/2 xl:w-1/3 pb-5">
            <menu-card-line class="mb-base" :route="item.route" :icon="item.icon" :action="item.action"/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import MenuCardLine from "./MenuCardLine.vue";

export default {
  components: {
    MenuCardLine
  },
  props: {
      pannels: {
          type:Array,
          required:true
      }
  }
};
</script>
