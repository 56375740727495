<template>
    <vx-card class="overflow-hidden shadow-none">
        <div slot="no-body">
            <div class="p-6 text-center">
                <img :src="iconPath" width="50px" class="p-3 inline-flex">
                <div class="truncate">
                    <span>{{ title }}</span>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>

export default{
    props: {
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    computed: {
        iconPath: function() {
            try{
                return require(`@/assets/images/icons/icon_backend_${this.icon}.svg`)
            } catch (e) {
                console.error(e)
                return '';
            }
        }
    }
}
</script>
