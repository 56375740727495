var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-wrap" },
    _vm._l(_vm.pannels, function(pannel) {
      return _c(
        "div",
        {
          key: pannel.title,
          staticClass: "w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 pr-8"
        },
        [
          _c("h4", [_vm._v(_vm._s(_vm.$t(pannel.title)))]),
          _c("vs-divider"),
          _c("p", {}, [_vm._v(_vm._s(_vm.$t(pannel.subtitle)))]),
          _c(
            "div",
            { staticClass: "vx-row mt-4" },
            _vm._l(pannel.items, function(item) {
              return _c(
                "div",
                {
                  key: item.title,
                  staticClass: "vx-col w-1/2 md:w-1/2 xl:w-1/3 pb-5"
                },
                [
                  _c("menu-card-line", {
                    staticClass: "mb-base",
                    attrs: {
                      route: item.route,
                      icon: item.icon,
                      action: item.action
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }