<template>

    <menu-card-line-content
      class="cursor-pointer text-gray no-underline"
      v-if="action"
      :title="$t(action.title)"
      :icon="icon"
      @click.native="action.action($route.params.uuid)" />

    <a v-else-if="route.props && route.props.target" :href="route.path" :target="route.props.target" class="text-gray no-underline">
        <menu-card-line-content :title="$t(route.meta.pageTitle)" :icon="icon" />
    </a>
    <router-link v-else :to="{name: route.name, params: {uuid: this.$route.params.uuid}}" class="text-gray no-underline">
        <menu-card-line-content :title="$t(route.meta.pageTitle)" :icon="icon" />
    </router-link>
</template>

<script>
import MenuCardLineContent from "./MenuCardLineContent.vue";
export default{
    components: {
        MenuCardLineContent
    },
    props: {
        icon: {
            type: String,
            required: true
        },
        route: {
            type: Object,
            required: false
        },
        action: {
            type: Object,
            required: false
        }
    },
}
</script>
