var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.action
    ? _c("menu-card-line-content", {
        staticClass: "cursor-pointer text-gray no-underline",
        attrs: { title: _vm.$t(_vm.action.title), icon: _vm.icon },
        nativeOn: {
          click: function($event) {
            return _vm.action.action(_vm.$route.params.uuid)
          }
        }
      })
    : _vm.route.props && _vm.route.props.target
    ? _c(
        "a",
        {
          staticClass: "text-gray no-underline",
          attrs: { href: _vm.route.path, target: _vm.route.props.target }
        },
        [
          _c("menu-card-line-content", {
            attrs: { title: _vm.$t(_vm.route.meta.pageTitle), icon: _vm.icon }
          })
        ],
        1
      )
    : _c(
        "router-link",
        {
          staticClass: "text-gray no-underline",
          attrs: {
            to: {
              name: _vm.route.name,
              params: { uuid: this.$route.params.uuid }
            }
          }
        },
        [
          _c("menu-card-line-content", {
            attrs: { title: _vm.$t(_vm.route.meta.pageTitle), icon: _vm.icon }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }